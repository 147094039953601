body {
    background-image: url("../assets/images/background.svg");
    background-size:cover;
    background-position:center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height:100%;
}

.dragzone {
  border-radius: 2;
  border-width: 2;
  align-items:center;
  border-style: dashed;
  border-color: white;
  transition: 'border .24s ease-in-out';
  background-color:#212529;
}

.imgSize {
  height: 50px;
  width: auto; 
}

.imgSort {
  height: 150px;
  width: auto; 
}

.imgSizeEdit {
  width: auto; 
}

.dropZone:hover {
  background-color: #2125297e;
}