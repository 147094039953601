.showcaseProjectPage {
  background-image: url(../assets/images/projectBg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 17.625rem;
}

@media only screen and (max-width: 990px) {
  .showcaseProjectPage {
    background-attachment: initial !important;
    height: 25vh !important;
  }
}

.captionProjectPage {
  position: absolute;
  left: 0;
  top: 22%;
  width: 100%;
  text-align: center;
  z-index: 1;
  letter-spacing: 10px;
}

.flex-row {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.flex-row > div {
  flex-basis: 50%;
  padding: 1em;
}

.intro.plain {
  background: #212121;
  padding: 2rem 0 2rem;
}

.right {
  text-align: left;
}

.hug {
  margin-top: 0;
  font-variant-caps: normal;
}

.hugHeading {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
