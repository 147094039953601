.flex-row {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: stretch;
}

.flex-row > div {
    flex-basis: 50%;
    padding: 1em;
}

.intro.plainC {
    background: #212529;
    padding: 2rem 0 2rem;
    height: auto;
}

.contactText {
    font-size:24px;
}

.right {
    text-align: left;
}

.hug {
    margin-top: 0;
    font-variant-caps: normal;
}

.hugHeading {
    margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}