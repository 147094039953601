/* HTML */
html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #212529;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #212529;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

td {
  font-size: 1.15rem;
}

a {
  text-decoration: none !important;
}

@font-face {
  font-family: "Aller";
  src: url("../assets/font/Gilroy-Extrabold.ttf") format("opentype");
}

@font-face {
  font-family: "AllerD";
  src: url("../assets/font/Gilroy-Light.ttf") format("opentype");
}

* {
  font-family: "AllerD", Arial, sans-serif;
}

p {
  margin-top: 0;
  margin-bottom: 0.1rem !important;
  font-size: 1rem !important;
}

#aboutme {
  font-variant: normal;
  text-align: left;
  margin-top: 0;
}

body {
  margin: 0;
  font-family: "AllerD", Arial, sans-serif;
  font-size: 17px;
  font-variant-caps: all-petite-caps;
  color: whitesmoke;
}

.appColor {
  background-color: white !important;
}

.navbar-dark .navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;
  border: 2px solid;
}

.showcase {
  background-image: url("../assets/images/kralovka.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
}

@media only screen and (max-width: 990px) {
  .showcase {
    background-attachment: initial !important;
  }
  .showcase2 {
    display: flex;
    background-attachment: initial !important;
    height: 25vh !important;
    align-items: center;
  }
}

.showcase2 {
  background-image: url("../assets/images/projectBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 17.625rem;
  filter: grayscale(70%);
}

.scshowcase {
  color: #212529;
}

.caption {
  position: absolute;
  left: 0;
  top: 45%;
  width: 100%;
  text-align: center;
  letter-spacing: 10px;
}

.captionArrow {
  position: absolute;
  left: 0;
  top: 90%;
  width: 100%;
  text-align: center;
}

.captionProject {
  position: absolute;
  left: 0;
  top: 35%;
  width: 100%;
  text-align: center;
  z-index: 1;
  letter-spacing: 10px;
}

.captionContact {
  width: 100%;
  text-align: center;
  z-index: 1;
}

.login {
  background-color: #212529;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

.zarovnat {
  margin: 0 auto;
}

@media only screen and (min-width: 990px) {
  .navbar-navi {
    font-size: 2rem !important;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0.1rem;
  }
}

@media only screen and (min-width: 990px) {
  .navbar-navl {
    border-left: 1.5px solid whitesmoke;
    line-height: 15px;
    margin-top: 8px;
    margin-left: 5px;
  }
}

@media only screen and (min-width: 991px) {
  .navbar-navll {
    font-size: 1.75rem !important;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.flexbox-centering {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.yuki {
  width: 100%;
}

.fab {
  font-size: 25px;
}

.kontakt2 {
  font-size: 24px;
}

.profile {
  color: #212529 !important;
}

.navbar {
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
  font-family: "AllerD", Arial, sans-serif;
  transition: 0.75s;
  text-align: start;
}

.navbar.colorChange {
  background-color: black !important;
  transition: 0.75s;
}

.nav-link {
  font-family: "AllerD" !important;
  font-size: 1.25em !important;
  transition: 0.4s !important;
  display: block;
}

.nav-link:hover {
  font-family: "AllerD" !important;
  transform: scale(1.1);
  transition: 0.4s;
}

.footer {
  background-color: #212529 !important;
  padding: 3rem 0px;
  height: auto;
}

.line2 {
  height: 5px;
  margin: 0 auto;
  background-color: #212529;
}

.downarrow {
  font-size: 70px;
  color: whitesmoke;
  transition: all 0.5s ease-in-out;
}

.downarrow:hover {
  transform: scale(1.1);
  color: #212529;
}

.tel {
  color: whitesmoke;
  font-size: 1.15rem;
}

.cby {
  color: white;
  transition: 0.3s;
}

.cby:hover {
  color: rgb(4, 86, 238);
  transition: 0.3s;
}

.sizeReq {
  font-size: 14px;
}

.fbico {
  color: #fff;
  perspective: 1000;
  transition: 0.3s;
}

.fbico:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.pintico {
  color: #fff;
  perspective: 1000;
  transition: 0.3s;
}

.btns {
  border-radius: 0 !important;
  transition: all 0.3s ease-in-out 0s !important;
}

.btn-successs {
  background-color: #00704a !important;
  transition: all 0.3s ease-in-out 0s;
  border: 1px solid #00704a !important;
}

.btn-successs:hover {
  background-color: #fff !important;
  color: #00704a !important;
  transition: all 0.3s ease-in-out 0s;
}

.form-control {
  border-radius: 0 !important;
}

.pintico:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.footersvg {
  width: 75px;
}

.navbarsvg {
  width: 55px;
}

.wrapper .icon {
  padding: 10px;
  text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.6);
  transition: all ease-in-out 150ms;
}

.wrapper .icon:hover {
  transform: scale(1.02);
}

.igico {
  color: #fff;
  transition: 0.3s;
}

.igico:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.navbar-fixed-top {
  width: 100vw !important;
  display: block;
}
