.container2 {
  padding: 1em 0;
  float: left;
  width: 50%;
}

@media screen and (max-width: 640px) {
  .container {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .container {
    width: 33.33333%;
  }
}

.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
}

.content .content-overlay {
    background: #21252974;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    opacity: 1;
}

.content:hover .content-overlay {
  background: #212529a6;
}

.content .content-overlayIco {
  background: #212529b4;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlayIco {
  opacity: 1;
}

.content-image {
  width: 400px;
  height: 300px;
  object-fit: cover;
  margin-top: auto;
}

.content-imageModal {
  margin: 0;
  width: 100%;
  height: 100%;
}

.content-text {
  color: whitesmoke;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 2em;
  padding-right: 1em;
  width: 100%;
  top: 40%;
  opacity: 0;
  color: whitesmoke !important;
  opacity: 1;
  transition: 0.2s;
}

.content-detailsIco {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  color: whitesmoke !important;
}

.content:hover .content-detailsIco {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content .content-details h4 {
  top: 50%;
  left: 50%;
  font-size: 1.05rem !important;
  transition: 0.2s;
}

.contents .content-details p {
  top: 50%;
  left: 50%;
  font-size: 0.90rem !important;
  transition: 0.2s;
}

.content-details h4 {
  color: #fff !important;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  transition: 0.2s;
}

.content-details p {
  color: #fff !important;
  font-size: 0.8em;
  transition: 0.2s;
}

.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
}

.arrows path {
  stroke: whitesmoke;
  fill: transparent;
  stroke-width: 1.5px;
  animation: arrow 3s infinite;
  -webkit-animation: arrow 3s infinite;
}

.yukiIco {
  content: url(../assets/images/icoyuki.svg);
  width: 75px;
  transition: 1s;
}

.yukiIcoLoading {
  content: url(../assets/images/icoyukiBlack.svg);
  width: 50px;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loadingText:after {
  content: " .";
  animation: dots 0.75s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
  }
}

.yukiIco:hover {
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}
